import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import {
  Grid,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material'
import { baseURL } from './BaseStringUrl'
import exportFromJSON from 'export-from-json'

const SeoDataPoints = ({ projects }) => {
  const [selectedProject, setSelectedProject] = useState('')
  const [loading, setLoading] = useState(false)

  const exportData = async () => {
    if (selectedProject === '') {
      alert(JSON.stringify(`Please select project`))
    } else {
      setLoading(true)
      await axios
        .post(`${baseURL}/seo-data-points`, { project: selectedProject })
        .then((response) => {
          let data = response.data.message
          const exportType = exportFromJSON.types.csv
          for (let website of data) {
            let arr = []
            let filename = ''
            for (let dataKey in website) {
              arr.push(
                {
                  key: '',
                  value: '',
                },
                {
                  key: dataKey,
                  value: '',
                }
              )
              for (let key in website[dataKey]) {
                if (key === 'name') filename = website[dataKey][key]
                arr.push({
                  key: key,
                  value: website[dataKey][key],
                })
              }
            }
            exportFromJSON({
              data: arr,
              fileName: `${filename}-SeoDataPoints`,
              exportType,
            })
          }
          setLoading(false)
        })
        .catch((err) => {
          alert(
            JSON.stringify(`${err.data.message}, status: ${err.data.status}`)
          )
        })
    }
  }

  return (
    <div className='App'>
      {loading === true && <CircularProgress />}
      {loading === false && (
        <Grid container spacing={3}>
          <Grid item xs={6} md={6}>
            <TextField
              value={selectedProject}
              onChange={(e) => setSelectedProject(e.target.value)}
              select
              label='Select Project'
              style={{ width: '100%' }}
            >
              {projects.map((project) => {
                return (
                  <MenuItem value={project.project}>{project.project}</MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button
              component='label'
              variant='contained'
              startIcon={<CloudDownloadIcon />}
              onClick={exportData}
            >
              Download
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default SeoDataPoints
