import * as React from 'react'
import { useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import { Container, styled } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Navbar from '../Navbar'
import axios from 'axios'
import { baseURL } from '../BaseStringUrl'
import { useNavigate } from 'react-router'

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {'Copyright © '}
      <Link color='inherit' href='#'>
        Hurekatek
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const defaultTheme = createTheme()

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.red,
  color: theme.palette.error.light,
  padding: theme.spacing(0),
}))

export default function SignUp() {
  let navigate = useNavigate()
  const [emailExist, setEmailExist] = useState(false)
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem('userInfo'))
  )

  useEffect(() => {
    if (
      userInfo !== null &&
      userInfo.access === true &&
      (userInfo.token !== '' || userInfo.token !== undefined)
    ) {
      navigate('/upload-form')
    }
  }, [userInfo])

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    if (
      data.get('firstName') === '' ||
      data.get('lastName') === '' ||
      data.get('email') === '' ||
      data.get('password') === ''
    ) {
      alert(JSON.stringify(`Please fill all required fields`))
    } else if (emailExist === true) {
      alert(JSON.stringify(`Email Already Exists`))
    } else {
      await axios
        .post(`${baseURL}/signup`, {
          firstname: data.get('firstName'),
          lastname: data.get('lastName'),
          email: data.get('email'),
          password: data.get('password'),
          is_admin: false,
        })
        .then((response) => {
          alert(JSON.stringify(`${response.data.response}`))
          navigate('/')
        })
        .catch((err) => {
          alert(JSON.stringify(`${err.response.data.response}`))
          localStorage.clear()
        })
    }
  }

  const handleEmailExits = async (event) => {
    event.preventDefault()
    await axios
      .post(`${baseURL}/email-check`, {
        email: event.target.value,
      })
      .then((response) => {
        if (response.data.exists === true) {
          setEmailExist(true)
        } else {
          setEmailExist(false)
        }
      })
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Navbar />
      <Container component='main' maxWidth='xs'>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sign up
          </Typography>
          <Box
            component='form'
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete='given-name'
                  name='firstName'
                  required
                  fullWidth
                  id='firstName'
                  label='First Name'
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id='lastName'
                  label='Last Name'
                  name='lastName'
                  autoComplete='family-name'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  onBlur={handleEmailExits}
                  autoComplete='email'
                />
                {emailExist && <Div>{'Email Already Exists'}</Div>}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type='password'
                  id='password'
                  autoComplete='new-password'
                />
              </Grid>
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Link href='/' variant='body2'>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}
