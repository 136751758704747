import React from 'react'
import UploadForm from './components/UploadForm'
import Login from './components/registration/Login'
import SignUp from './components/registration/SignUp'
import PendingApprovals from './components/PendingApprovals'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App() {
  return (
    <Router>
      <main>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/upload-form' element={<UploadForm />} />
          <Route path='/pending-approvals' element={<PendingApprovals />} />
        </Routes>
      </main>
    </Router>
  )
}

export default App
