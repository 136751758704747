import * as React from 'react'
import { useState, useEffect } from 'react'
import Navbar from './Navbar'
import {
  styled,
  CssBaseline,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Paper,
  CircularProgress,
  IconButton,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { baseURL } from './BaseStringUrl'
import axios from 'axios'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

function createData(firstname, lastname, email, date) {
  return { firstname, lastname, email, date }
}

export default function PendingApprovals() {
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(true)
  const [checkloader, setCheckloader] = useState(false)

  useEffect(() => {
    const abortController = new AbortController()
    const getPendingUsers = async () => {
      if (rows.length <= 0) {
        await axios
          .get(`${baseURL}/get-pending-users`, {
            signal: abortController.signal,
          })
          .then((res) => {
            console.log(res.data.message.length, '------')
            if (res.data.message.length > 0) {
              let data = res.data.message
              data.forEach((element, idx, arr) => {
                setRows((oldRow) => [
                  ...oldRow,
                  createData(
                    element.firstname,
                    element.lastname,
                    element.email,
                    element.date.value
                  ),
                ])
                if (idx === arr.length - 1) {
                  setLoading(false)
                }
              })
            } else {
              setRows([])
              setLoading(false)
            }
          })
          .catch((error) => {
            if (error.name === 'AbortError') {
              console.log('Fetching data was aborted')
            } else {
              console.error(error, '-----from else error')
            }
          })
      }
    }
    getPendingUsers()
    return () => abortController.abort()
  }, [])

  const onSubmit = async (action, email) => {
    setCheckloader(true)
    await axios
      .post(`${baseURL}/pending-approval`, { action: action, email: email })
      .then((res) => {
        setCheckloader(false)
        alert(JSON.stringify(`${res.data.message}`))
        if (res.data.status === 'Success') {
          window.location.reload(true)
        }
      })
  }

  function TableContentRender() {
    if (loading === true) {
      return <CircularProgress />
    } else if (loading === false) {
      if (rows.length > 0) {
        return (
          <TableContainer component={Paper}>
            <Table aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell align='left'>Email</StyledTableCell>
                  <StyledTableCell align='left'>
                    Registered Date
                  </StyledTableCell>
                  <StyledTableCell align='center'>Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow key={row.name}>
                    <StyledTableCell component='th' scope='row'>
                      {row.firstname} {row.lastname}
                    </StyledTableCell>
                    <StyledTableCell align='left'>{row.email}</StyledTableCell>
                    <StyledTableCell align='left'>{row.date}</StyledTableCell>
                    <StyledTableCell align='center'>
                      {checkloader === true && (
                        <CircularProgress
                          sx={{ color: 'grey.500' }}
                          color='inherit'
                        />
                      )}
                      {checkloader === false && (
                        <>
                          <IconButton onClick={() => onSubmit(true, row.email)}>
                            <CheckCircleIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => onSubmit(false, row.email)}
                          >
                            <CancelIcon />
                          </IconButton>
                        </>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      } else {
        return <h1>No Pending Approvals</h1>
      }
    }
  }

  return (
    <>
      <CssBaseline />
      <Navbar />
      <Container
        component='main'
        sx={{
          justifyContent: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 4,
            maxWidth: 1200,
            maxHeight: 550,
          },
        }}
      >
        <TableContentRender />
      </Container>
    </>
  )
}
