import * as React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import { baseURL } from './BaseStringUrl'
import {
  CssBaseline,
  Container,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
} from '@mui/material'
import UploadFile from './UploadFile'
import Navbar from './Navbar'
import KeywordAnalysis from './KeywordAnalysis'
import SeoDataPoints from './SeoDataPoints'

export default function UploadForm() {
  const [projectName, setProjectName] = useState('')
  const [website, setWebsite] = useState('')
  const [projects, setProjects] = useState([])

  useEffect(() => {
    const getProjects = async () => {
      await axios.get(`${baseURL}/get-all-projects`).then((res) => {
        setProjects(res.data.message)
      })
    }
    getProjects()
  }, [])

  const handleSubmit = async (data) => {
    let payload = {
      project_name: projectName,
      website: website,
    }

    await axios.post(`${baseURL}/upload-file`, payload).then((response) => {
      let data = response.data
      console.log(data, '-------axios data')
      setProjects(response.data.data)
      alert(
        JSON.stringify(
          `${response.data.message}, status: ${response.data.status}`
        )
      )
    })
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Navbar />
      <Container
        component='main'
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 4,
            width: 500,
            height: 300,
            maxWidth: 500,
            maxHeight: 500,
          },
        }}
      >
        <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Grid container direction='column' spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography component='h1' variant='h4' align='center'>
                Create Project
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <TextField
                value={projectName}
                onChange={(e) => setProjectName(e.target.value)}
                label='Project Name'
                style={{ width: '70%' }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                label='Website'
                style={{ width: '70%' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                component='label'
                onClick={handleSubmit}
                variant='contained'
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <UploadFile projects={projects} />
        </Paper>
        <Paper elevation={3} sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography component='h1' variant='h4' align='center'>
            Download Files
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h6' gutterBottom>
                Keyword Anaylsis
              </Typography>
              <KeywordAnalysis />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' gutterBottom>
                Seo Proposal Data Points
              </Typography>
              <SeoDataPoints projects={projects} />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </React.Fragment>
  )
}
