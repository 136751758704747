import React from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import { baseURL } from './BaseStringUrl'
import axios from 'axios'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Grid, Typography, MenuItem, TextField, Button } from '@mui/material'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

const UploadFile = ({ projects }) => {
  const { register, handleSubmit } = useForm()
  const [filename, setFileName] = useState('')
  // const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState('')

  const onSubmit = async (data) => {
    console.log(selectedProject, '----------selected')
    console.log(
      data.file[0],
      '----------data.file[0]',
      filename,
      selectedProject
    )
    const formData = new FormData()
    formData.append('file', data.file[0])
    formData.append('filename', filename)
    formData.append('project', selectedProject)
    console.log(formData, '--------------')
    await axios.post(`${baseURL}/upload-file`, formData).then((res) => {
      alert(JSON.stringify(`${res.data.message}, status: ${res.data.status}`))
    })
  }

  return (
    <div className='App'>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Typography component='h1' variant='h4' align='center'>
            Upload Files
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
            select
            label='Select Project'
            style={{ width: '50%' }}
          >
            {projects.map((project) => {
              return (
                <MenuItem value={project.project}>{project.project}</MenuItem>
              )
            })}
          </TextField>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            value={filename}
            onChange={(e) => setFileName(e.target.value)}
            select
            label='File Name'
            style={{ width: '50%' }}
          >
            <MenuItem value='OnPageBenchmark'>OnPage Benchmark</MenuItem>
            {/* <MenuItem value='OrganicKeywordsBenchmark'>
              Organic Keywords Benchmark
            </MenuItem> */}
            <MenuItem value='OrganicKeywordsBenchmarkExtended'>
              Organic Keywords Benchmark Extended
            </MenuItem>
            {/* <MenuItem value='OrganicKeywordsBenchmarkIntent'>
              Organic Keywords Benchmark Intent
            </MenuItem>
            <MenuItem value='KeywordSuggesion'>Keyword Suggesion</MenuItem> */}
            <MenuItem value='ReferringDomains'>Referring Domains</MenuItem>
            <MenuItem value='BacklinksData'>Backlinks Data</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Button
                component='label'
                variant='contained'
                startIcon={<CloudUploadIcon />}
                onChange={handleSubmit(onSubmit)}
              >
                Upload file
                <VisuallyHiddenInput type='file' {...register('file')} />
              </Button>
            </form>
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default UploadFile
