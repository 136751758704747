import React from 'react'
import Button from '@mui/material/Button'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import axios from 'axios'
import { baseURL } from './BaseStringUrl'
import exportFromJSON from 'export-from-json'

const KeywordAnalysis = () => {
  const exportData = async () => {
    await axios.get(`${baseURL}/keyword-analysis`).then((response) => {
      let data = response.data
      const fileName = 'keywordAnalysis'
      const exportType = exportFromJSON.types.csv
      exportFromJSON({ data, fileName, exportType })
    })
  }

  return (
    <div className='App'>
      <Button
        component='label'
        variant='contained'
        startIcon={<CloudDownloadIcon />}
        onClick={exportData}
      >
        Download
      </Button>
    </div>
  )
}

export default KeywordAnalysis
